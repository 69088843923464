<template>
    <div class="home">
      <div class="input-container">
        <input v-model="password" @keyup.enter="validatePassword" placeholder="Enter password" />
        <button @click="validatePassword">验证</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        password: ''
      };
    },
    methods: {
      validatePassword() {
        if (this.password === '20240501') {
          this.$router.push('/success_secret');
        } else {
          this.$router.push('/failure');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* 确保html和body的高度为100% */
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 使用100vh确保容器占据整个视口高度 */
    width: 100%;
    background-image: url('../src/assets/styles/lighthouse-8650151_1920.jpg'); /* 替换为你自己的背景图片路径 */
    background-size: cover; /* 确保背景图片覆盖整个容器 */
    background-position: center; /* 确保背景图片居中对齐 */
    background-repeat: no-repeat; /* 确保背景图片不重复 */
  }
  
  .input-container {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8); /* 可选：为输入容器添加一个半透明的背景色，以便更清晰地查看输入框 */
    padding: 20px;
    border-radius: 10px;
  }
  </style>
  


