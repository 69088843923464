import { createRouter, createWebHistory } from 'vue-router';
import Home from '../../views/HomePage.vue';
import Failure from '../../views/FailurePage.vue';
import SuccessPage from '../../views/SuccessPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { backgroundClass: 'bg-homepage' }, 
  },
  {
    path: '/success_secret',
    name: 'Success',
    component: SuccessPage,
    //meta: { backgroundClass: 'bg-otherpage' }, 
  },
  {
    path: '/failure',
    name: 'Failure',
    component: Failure,
    //meta: { backgroundClass: 'bg-otherpage' }, 
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

