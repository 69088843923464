<template>
  <div id="fullpage">
    <div
      class="section"
      v-for="(text, index) in sections"
      :key="index"
      :id="`section${index + 1}`"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import fullpage from 'fullpage.js';

export default {
  name: 'SuccessPage',
  setup() {
    let fullpageInstance;

    const sections = ref([
      '祝亲爱的，可爱的，美丽的彤宝：儿童节快乐~',
      '这是我们认识一个月的日子，首先感谢宝宝愿意给我这个机会实现一些“离谱”的幻想。我希望，这段时间带给宝宝的更多是满足和开心。',
      '之前说过的，这是我第一次干这种“坏事”，所以难免会有不成熟的地方，十分感谢宝宝的理解和包容（虽然有可能是被迫的~）',
      '认识宝宝后的每一天都是快乐幸福的，即使宝宝有时候会有一些些些些气人，不过，有的是办法治宝宝~对不对',
      '希望彤宝不止在儿童节，更要在每一个平常，无聊的日子里都能做一个无忧无虑的小朋友（做小狗也可以哦~）！',
      '桐&彤',
    ]);

    const initializeFullPage = () => {
      fullpageInstance = new fullpage('#fullpage', {
        autoScrolling: true,
        navigation: true
      });
    };

    const destroyFullPage = () => {
      if (fullpageInstance) {
        fullpageInstance.destroy('all');
      }
    };

    onMounted(() => {
      initializeFullPage();
    });

    onUnmounted(() => {
      destroyFullPage();
    });

    return {
      sections,
      initializeFullPage,
      destroyFullPage
    };
  }
};
</script>

<style scoped>
.section {
  text-align: center;
  font-size: 3em;
  color: white;
}
#section1 { background-color: #eea2a4; }
#section2 { background-color: #f1939c; }
#section3 { background-color: #e77c8e; }
#section4 { background-color: #f07c82; }
#section5 { background-color: #c45a65; }
#section6 { background-color: #ed5a65; }
</style>
