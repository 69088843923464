<template>
    <div class="failure">
      <h1 style="font-size: 10em; color: red;">快滚</h1>
    </div>
  </template>

  <style scoped>
  /* 确保html和body的高度为100% */
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .failure {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 使用100vh确保容器占据整个视口高度 */
    width: 100%;
    background-image: url('../src/assets/styles/fck.jpg'); /* 替换为你自己的背景图片路径 */
    background-size: cover; /* 确保背景图片覆盖整个容器 */
    background-position: center; /* 确保背景图片居中对齐 */
    background-repeat: no-repeat; /* 确保背景图片不重复 */
  }
  
  </style>
  
  